export const positionTop = 'top'
export const positionRight = 'right'
export const positionBottom = 'bottom'
export const positionLeft = 'left'

export const groupingSingle = 'single'
export const groupingSeries = 'series'
export const groupingPrimary = 'primary'
export const groupingSecondary = 'secondary'

export const alignAuto = 'auto'
export const alignRight = 'right'
export const alignTopRight = 'topRight'
export const alignBottomRight = 'bottomRight'
export const alignLeft = 'left'
export const alignTopLeft = 'topLeft'
export const alignBottomLeft = 'bottomLeft'
export const alignTop = 'top'
export const alignBottom = 'bottom'

export const axisTypeOrdinal = 'ordinal'
export const axisTypeTime = 'time'
export const axisTypeUtc = 'utc'
export const axisTypeLinear = 'linear'
export const axisTypeLog = 'log'

export const anchorPointer = 'pointer'
export const anchorClosest = 'closest'
export const anchorCenter = 'center'
export const anchorTop = 'top'
export const anchorBottom = 'bottom'
export const anchorLeft = 'left'
export const anchorRight = 'right'
export const anchorGridTop = 'gridTop'
export const anchorGridBottom = 'gridBottom'
export const anchorGridLeft = 'gridLeft'
export const anchorGridRight = 'gridRight'
