import {
  curveBasisClosed,
  curveBasisOpen,
  curveBasis,
  curveBundle,
  curveCardinalClosed,
  curveCardinalOpen,
  curveCardinal,
  curveCatmullRomClosed,
  curveCatmullRomOpen,
  curveCatmullRom,
  curveLinearClosed,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
  curveStepAfter,
  curveStepBefore
} from 'd3-shape'

export default {
  basisClosed: curveBasisClosed,
  basisOpen: curveBasisOpen,
  basis: curveBasis,
  bundle: curveBundle,
  cardinalClosed: curveCardinalClosed,
  cardinalOpen: curveCardinalOpen,
  cardinal: curveCardinal,
  catmullRomClosed: curveCatmullRomClosed,
  catmullRomOpen: curveCatmullRomOpen,
  catmullRom: curveCatmullRom,
  linearClosed: curveLinearClosed,
  linear: curveLinear,
  monotoneX: curveMonotoneX,
  monotoneY: curveMonotoneY,
  natural: curveNatural,
  step: curveStep,
  stepAfter: curveStepAfter,
  stepBefore: curveStepBefore
}
